var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "boxed-layout", staticStyle: { padding: "40px" } },
    [
      _c(
        "h2",
        { attrs: { id: "logs-page-heading", "data-cy": "logsPageHeading" } },
        [_vm._v("Logs")]
      ),
      _vm._v(" "),
      _vm.loggers
        ? _c("div", [
            _c("p", [
              _vm._v("There are " + _vm._s(_vm.loggers.length) + " loggers."),
            ]),
            _vm._v(" "),
            _c("span", [_vm._v("Filter")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filtered,
                  expression: "filtered",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.filtered },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.filtered = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "table",
              {
                staticClass: "table table-sm table-striped table-bordered",
                attrs: { "aria-describedby": "Logs" },
              },
              [
                _c("thead", [
                  _c("tr", { attrs: { title: "click to order" } }, [
                    _c(
                      "th",
                      {
                        attrs: { scope: "col" },
                        on: {
                          click: function ($event) {
                            return _vm.changeOrder("name")
                          },
                        },
                      },
                      [_c("span", [_vm._v("Name")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "col" },
                        on: {
                          click: function ($event) {
                            return _vm.changeOrder("level")
                          },
                        },
                      },
                      [_c("span", [_vm._v("Level")])]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(
                  _vm.orderBy(
                    _vm.filterBy(_vm.loggers, _vm.filtered),
                    _vm.orderProp,
                    _vm.reverse === true ? 1 : -1
                  ),
                  function (logger) {
                    return _c("tr", { key: logger.name }, [
                      _c("td", [_c("small", [_vm._v(_vm._s(logger.name))])]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm",
                            class:
                              logger.level === "TRACE"
                                ? "btn-primary"
                                : "btn-light",
                            on: {
                              click: function ($event) {
                                return _vm.updateLevel(logger.name, "TRACE")
                              },
                            },
                          },
                          [_vm._v("\n            TRACE\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm",
                            class:
                              logger.level === "DEBUG"
                                ? "btn-success"
                                : "btn-light",
                            on: {
                              click: function ($event) {
                                return _vm.updateLevel(logger.name, "DEBUG")
                              },
                            },
                          },
                          [_vm._v("\n            DEBUG\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm",
                            class:
                              logger.level === "INFO"
                                ? "btn-info"
                                : "btn-light",
                            on: {
                              click: function ($event) {
                                return _vm.updateLevel(logger.name, "INFO")
                              },
                            },
                          },
                          [_vm._v("\n            INFO\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm",
                            class:
                              logger.level === "WARN"
                                ? "btn-warning"
                                : "btn-light",
                            on: {
                              click: function ($event) {
                                return _vm.updateLevel(logger.name, "WARN")
                              },
                            },
                          },
                          [_vm._v("\n            WARN\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm",
                            class:
                              logger.level === "ERROR"
                                ? "btn-danger"
                                : "btn-light",
                            on: {
                              click: function ($event) {
                                return _vm.updateLevel(logger.name, "ERROR")
                              },
                            },
                          },
                          [_vm._v("\n            ERROR\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm",
                            class:
                              logger.level === "OFF"
                                ? "btn-secondary"
                                : "btn-light",
                            on: {
                              click: function ($event) {
                                return _vm.updateLevel(logger.name, "OFF")
                              },
                            },
                          },
                          [_vm._v("\n            OFF\n          ")]
                        ),
                      ]),
                    ])
                  }
                ),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }